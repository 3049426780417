import './Plans.css'
import { plansData } from '../../data/plansData';
import whiteTick from "../../assets/whiteTick.png"
const Plans = () => {
    return (
        <div className='plans-container' id='plans'>

            <div className="blur blur-plan1"></div>
            <div className="blur blur-plan2"></div>
            <div className="programs-headers" style={{ gap: "2rem" }}>
                <span className='stroke-text'>READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className='stroke-text'>NOW WITHOUS</span>
            </div>

            <div className="plans">
                {
                    plansData.map((plan, i) => (
                        <div className='plan' key={i}>
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>${plan.price}</span>

                            <div className='features'>
                                {
                                    plan.features.map((fe, i) => (
                                        <div className='feature'>
                                            <img src={whiteTick} alt="white" />
                                            <span key={i}>{fe}</span>
                                        </div>
                                    ))
                                }
                            </div>

                            <div>
                                <span>
                                    See more benefits -{'>'}
                                </span>
                            </div>

                            <button className='btn'>Join now</button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Plans
