import './Hero.css'
import Header from '../Header/Header';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter';

const Hero = () => {
    const transition = { type: 'tween', duration: 3 }
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id='home'>

            <div className='blur blur-hero'></div>
            <div className="left-h">
                <Header />

                {/* the best ad */}

                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile ? "165px" : "238px" }}
                        whileInView={{ left: '8px' }}
                        transition={transition}
                    ></motion.div>
                    <span>the best fitness club in the town</span>
                </div>

                {/* Hero heading  */}

                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>

                    <div>
                        <span>Ideal body</span>
                    </div>

                    <div>
                        <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
                    </div>
                </div>

                {/* Figures */}

                <div className="figures">
                    <div>
                        <span style={{ display: "flex" }}>
                            <span>+</span>
                            <NumberCounter end={140} start={100} delay='4' prefix='+' />
                        </span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span style={{ display: "flex" }}>
                            <span>+</span>
                            <NumberCounter end={978} start={800} delay='4' prefix='+' />
                        </span>
                        <span>members joines</span>
                    </div>
                    <div>
                        <span style={{ display: "flex" }}>
                            <span>+</span>
                            <NumberCounter end={50} start={0} delay='4' prefix='+' />
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>

                {/* Hero buttons  */}

                <div className="hero-buttons">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>

            </div>
            <div className="right-h">
                <button className='btn btn-navbar'>Join Now</button>

                <motion.div
                    transition={{ ...transition, type: "spring" }}
                    whileInView={{ right: "4rem" }}
                    initial={{ right: '-1rem' }}
                    className='heart-rate'>
                    <img src={Heart} alt="heart" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* Hero images */}

                <img src={hero_image} alt="hero_image" className='hero-image' />
                <motion.img
                    transition={{ ...transition, type: "spring" }}
                    whileInView={{ right: "20rem" }}
                    initial={{ right: '11rem' }}
                    src={hero_image_back} alt="hero_image-back" className='hero-image-back' />

                {/* Calories */}

                <motion.div className="calories"
                    transition={{ ...transition, type: "spring" }}
                    whileInView={{ right: "28rem" }}
                    initial={{ right: '37rem' }}
                >
                    <img src={Calories} alt="calories" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Hero
